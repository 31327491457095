<template>
  <div>
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("facilitySearchPage.pageTitle") }}</strong>
            <CButton color="info" @click="onCreateClicked()" v-if="isAdmin">{{ $t("common.create") }}</CButton>
          </CCardHeader>
          <CCardBody>
            <p>{{ $t("facilitySearchPage.facilitySearchGuide") }}</p>
            <CForm class="search-filter" @submit.prevent>
              <CRow class="mb-2">
                <CCol sm="1" class="search-btn">
                  <!-- #region 検索条件選択 -->
                  <label class="editor-title">{{
                    $t("facilitySearchPage.searchCriteria")
                  }}</label>
                </CCol>
                <CCol sm="2">
                  <CSelect
                    class="search-lbl"
                    :options="queryOptions"
                    :value.sync="selectedQuery"
                  />
                  <!-- #endregion 検索条件選択 -->
                </CCol>
                <!-- #region 宿名検索 -->
                <template v-if="isSelectedQuery('facilityName')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="
                        $t('facilitySearchPage.facilityNamePlaceholderText')
                      "
                      v-model="query.facilityName"
                      type="text"
                      @keydown.enter="onEnterKeyPress($event, 'facilityName')"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('facilityName')"
                      class="info-button"
                      :disabled="!query.facilityName"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template>
                <!-- #endregion 宿名検索 -->

                <!-- 拠点検索 -->
                <template v-if="isSelectedQuery('branch')">
                  <CCol sm="7" lg="6">
                    <CSelect
                      class="col-sm-12 px-1"
                      :options="branchOffice"
                      :value.sync="query.selectedBranch"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      class="info-button"
                      color="info"
                      @click="onSearchClicked('branchId')"
                    >
                      <i class="icon cil-search"></i>
                      {{ $t("facilitySearchPage.searchButton") }}
                    </CButton>
                  </CCol>
                </template>

                <!-- #region 全国地方公共団体コード検索 -->
                <!-- <template v-if="isSelectedQuery('areaId')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="$t('facilitySearchPage.areaidPlaceholderText')"
                      v-model="query.areaId"
                      type="text"
                      @keydown.enter="onSearchQueryEnter($event, 'areaId')"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('areaId')"
                      class="info-button"
                      :disabled="!query.areaId"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template> -->
                <!-- #endregion 全国地方公共団体コード検索 -->

                <!-- #region タグ名検索 -->
                <!-- 一時的にコメントアウトします-->
                <!-- <template v-if="isSelectedQuery('tagName')">
                  <CCol ssm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="
                        $t('facilitySearchPage.tagNamePlaceholderText')
                      "
                      v-model="query.tagName"
                      type="text"
                      @keydown.enter="onSearchQueryEnter($event, 'tagName')"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('tagName')"
                      class="info-button"
                      :disabled="!query.tagName"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template> -->
                <!-- #endregion タグ名検索 -->

                <!-- #region 郵便番号検索 -->
                <template v-if="isSelectedQuery('zipcode')">
                  <CCol sm="7" lg="6">
                    <CInput
                      class="search-input"
                      :placeholder="
                        $t('facilitySearchPage.zipcodePlaceholderText')
                      "
                      v-model="query.zipcode"
                      @keydown.enter="onEnterKeyPress($event, 'zipcode')"
                      type="text"
                    />
                  </CCol>
                  <CCol sm="3" lg="3">
                    <CButton
                      @click="onSearchClicked('zipcode')"
                      class="info-button"
                      :disabled="!query.zipcode"
                    >
                      <i class="icon cil-search"></i> {{ $t("common.search") }}
                    </CButton>
                  </CCol>
                </template>
                <!-- #endregion 郵便番号検索 -->

              <!-- #region 電話番号検索 -->
              <template v-if="isSelectedQuery('tel')">
                <CCol sm="7" lg="6">
                  <CInput
                    class="search-input"
                    :placeholder="$t('facilitySearchPage.telPlaceholderText')"
                    v-model="query.tel"
                    @keydown.enter="onEnterKeyPress($event, 'tel')"
                    type="text"
                  />
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    @click="onSearchClicked('tel')"
                    class="info-button"
                    :disabled="!query.tel"
                  >
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </CCol>
              </template>
              <!-- #endregion 電話番号検索 -->

              <!-- #region エリア検索 -->
              <template v-if="isSelectedQuery('area')">
                 <CCol sm="7" lg="6">
                  <!-- #area dropdow region -->
                  <section class="area-filter-section d-inline-block col-sm-12 px-1">

                    <CButton
                      color="secondary"
                      class="bg-white border-white mb-2"
                      variant="outline"
                      block
                      @click="areaListBox = !areaListBox"
                    >
                      <CIcon
                        class="float-left text-mikan"
                        name="cil-location-pin"
                      />
                      <span class="text-dark">{{query.selectedAreaInfo.areaId ? query.selectedAreaInfo.name: $t("facilitySearchPage.selectedArea") }}</span>
                    </CButton>
                    <CCard
                      v-if="areaListBox"
                      class="area-list-box border-white"
                      :key="reLoad"
                    >
                      <CCardBody>
                        <ul class="prefecture-list">
                          <li
                            class="area-list"
                            v-for="area in fullAreaList"
                            :key="area.name"
                          >

                            <span
                              class="core-area d-block"
                              @click="area.display=!area.display"
                            > {{area.name}}

                              <i
                                class=" bi float-right"
                                :class="area.display ? 'bi-chevron-down':'bi-chevron-right'"
                              ></i>
                            </span>
                            <transition name="slide">
                              <ul
                                class="ken-list"
                                v-if="area.display"
                              >
                                <li
                                  v-for="ken in area.childs"
                                  :key="ken.areaId"
                                >
                                  <span class="ken-title">
                                    <label
                                      :for="'flexCheckChecked'+ken.areaId"
                                      class="ml-1 my-1"
                                    >
                                      <!-- @click="isSelected(ken)" -->
                                      <input
                                        type="radio"
                                        :id="'flexCheckChecked'+ken.areaId"
                                        v-model="query.selectedAreaInfo"
                                        v-bind:value="ken"
                                      >

                                      {{ken.name}}
                                    </label>
                                    <i
                                      class="bi float-right mr-1 w-25 text-right"
                                      @click="onDisplayCityClicked(ken)"
                                      :class="ken.display ?  'bi-chevron-down':'bi-chevron-right'"
                                    ></i>
                                  </span>
                                  <transition name="slide">
                                    <ul
                                      class="city-list"
                                      v-if="ken.display"
                                    >
                                      <li
                                        v-for="city in ken.childs"
                                        :key="city.areaId"
                                      >
                                        <label
                                          :for="'flexCheckChecked'+city.areaId"
                                          class="ml-1 city-name"
                                        >
                                          <input
                                            type="radio"
                                            v-model="query.selectedAreaInfo"
                                            v-bind:value="city"
                                            :id="'flexCheckChecked'+city.areaId"
                                            class="city-check"
                                          >
                                          <span>{{city.name}}</span>
                                        </label>
                                      </li>
                                    </ul>
                                  </transition>
                                </li>
                              </ul>
                            </transition>
                          </li>
                        </ul>

                        <CSpinner
                          color="info"
                          class="mx-auto d-block my-4"
                          v-if="spinnerSide"
                        />
                          <!-- style="width:4rem;height:4rem;" -->
                        <!-- {{query.selectedAreaInfo}} -->
                        <!-- <pre><code>{{fullAreaList}}</code></pre> -->
                      </CCardBody>
                      <CCardFooter>
                        <CButton
                          variant="outline"
                          color="dark"
                          @click="areaListBox= false"
                        >
                          {{$t('common.close')}}
                        </CButton>

                      </CCardFooter>
                    </CCard>
                  </section>
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    class="info-button"
                    color="info"
                    @click="onSearchClicked('areaIds')"
                  >
                    <i class="icon cil-search"></i>
                    {{ $t("facilitySearchPage.searchButton") }}
                  </CButton>
                </CCol>
              </template>
              <!-- #endregion エリア検索 -->

              </CRow>
            </CForm>
              <transition name="fade">
                <CRow v-if="facilityNotFound">
                  <CCol lg="6">
                    <CAlert color="danger">{{
                      $t("facilitySearchPage.facilityNotFoundAlert")
                    }}</CAlert>
                  </CCol>
                </CRow>
              </transition>
          </CCardBody>

          <CCollapse :show="collapse" :duration="200">
            <CCardBody>
              <div class="user-info-table">
                <dl class="row">
                  <dt class="col-sm-12">
                    <h4 class="sub-title">
                      {{ $t("facilitySearchPage.facilityList") }}
                    </h4>
                    <p>
                      <span>{{ $t("facilitySearchPage.totalNumber") }}</span>: {{ facilityCount }} |
                      <span>{{ $t("facilitySearchPage.resultNumber") }}</span>: {{ facilities.length }}
                    </p>
                  </dt>
                </dl>
                <CRow>
                  <CCol
                    sm="5"
                    class="paginationInput"
                  >
                    <label class="mr-2 float-left">{{$t('facilitySearchPage.pagenationLabel')}}</label>
                    <CSelect
                      placeholder=""
                      horizontal
                      type="number"
                      :options="facilityPagenationCounts"
                      :value.sync="facilityPagenationSelected"
                    />
                  </CCol>
                </CRow>
                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  size="sm"
                  :items="facilities"
                  :fields="facilityTableFields"
                  head-color="light"
                  table-filter
                  column-filter
                  sorter
                  itemps-per-page-select
                  :items-per-page="facilityPagenationSelected"
                  pagination
                  v-if="!facilitiesLoading"
                  :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                >
                  <!-- sorter
                  :sorterValue.sync="tableSorter" -->

                  <td class="text-center" slot="branch" slot-scope="{ item }">
                    <strong>{{ item.branch }}</strong>
                  </td>
                  <td class="text-center" slot="uniqueId" slot-scope="{ item }">
                    <strong>{{ item.uniqueId }}</strong>
                  </td>
                  <td class="text-left" slot="name" slot-scope="{ item }">
                    <CLink
                      @click="goPreview(item)"
                      v-c-tooltip="'新規タブでプレビュー表示します'"
                    >
                      <!-- <CLink
                      @click="goPreview(item.uniqueId)"
                      v-c-tooltip="'新規タブでプレビュー表示します'"
                      > -->
                      <strong class="text-info">{{ item.name }}</strong>
                    </CLink>
                  </td>
                  <td class="text-center" slot="zipcode" slot-scope="{ item }">
                    <strong>{{ item.zipcode }}</strong>
                  </td>
                  <td class="text-left" slot="address" slot-scope="{ item }">
                    <strong>{{ item.address }}</strong>
                  </td>
                  <td class="text-center" slot="telephone" slot-scope="{ item }">
                    <strong>{{ item.telephone }}</strong>
                  </td>
                  <td class="text-center" slot="statusLabel" slot-scope="{ item }">
                    <strong>{{ item.statusLabel }}</strong>
                  </td>

                  <template #facility_select="{ item }">
                    <td class="w-15 py-2 text-center">
                      <CButton
                        class="w-auto d-inline-block mr-2"
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onManagementClicked(item.uniqueId)"
                        >
                        <i class="cil-hand-point-right"></i> 
                        {{$t("facilitySearchPage.facilitiesList.tableBody.managementButton")}}
                      </CButton>
                      <CButton
                        v-if="isAdmin"
                        class="mt-0 w-auto d-inline-block"
                        color="info"
                        square
                        block
                        size="sm"
                        @click="onRelationClicked(item.uniqueId)"
                        >
                        <i class="cil-hand-point-right"></i> 
                        {{$t("facilitySearchPage.facilitiesList.tableBody.userRelationButton")}}
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import HOTEL_STATUS_LABEL from "@/mixins/property";
import HOTEL_STATUS from "@/mixins/property";

const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
};

const rolePath = {
    [ROLE.ADMIN]: "admin",
    [ROLE.STAFF]: "staff"
  };


import i18n from "@/i18n";

export default {
  // components: { Loading },
  name: "FacilitySearch",

  mixins: [HOTEL_STATUS_LABEL, HOTEL_STATUS],

  data() {
    return {
      facilityNotFound: false,
      facilitiesLoading: false,
      areaListBox: false,
      spinnerSide: true,
      reLoad: 0,
      collapse: false,
      collapseDuration: 0,
      facilityCount: 0,
      facilityPagenationCounts: [
        { value: 5 },
        { value: 10 },
        { value: 20 },
        { value: 50 },
      ],
      facilityPagenationSelected: 5,

       /** 選択中の検索条件 */
      selectedQuery: "facilityName",

      /** 検索クエリ */
      query: {
        // areaId: "",
        tagName: "",
        zipcode: "",
        tel: "",
        facilityName: "",
        selectedBranch:"",
        selectedAreaInfo:{}
      },

      /**支店一覧 */
      branchOffice:[],

      /**全エリア情報 */
      fullAreaList :[],

      /** 検索条件選択 */
      queryOptions: [
        {value: "facilityName",label: `宿名`,},
        {value: "branch",label: `拠点`,},
        // { value: "areaId", label: `全国地方公共団体コード` },
        // { value: "tagName", label: i18n.t("facilitySearchPage.tagName") },
        { value: "zipcode", label: `郵便番号` },
        { value: "tel", label: `電話番号` },
        { value: "area", label: `エリア` }
      ],

      /** 宿検索結果 */
      facilities: [],

      /** リクエストに使う宿情報 */
      requestFacility: {
        uniqueId: "",
      },

      /** 宿一覧表示用 */
      facilityTableFields: [
        {
          key: "branch",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.branch"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
          _style: "width:7%",
        },
        {
          key: "uniqueId",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.id"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
          _style: "width:11%",
        },
        {
          key: "name",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.name"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "zipcode",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.zipcode"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
          _style: "width:6%",
        },
        {
          key: "address",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.address"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
          _style: "width:20%",
        },
        {
          key: "telephone",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.telephone"
          )}`,
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
          _style: "width:8%",
        },
        {
          key: "statusLabel",
          label: "Status",
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
          _style: "width:4%",
        },
        {
          key: "facility_select",
          label: `${i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.action"
          )}`,
          sorter: false,
          filter: false,
          _classes: "text-center bg-light",
          _style: "width:16%",
        },
      ],
    };
  },

  computed: {

    // ページ遷移先のロール判定
    pathRole() {
      const routeRole = this.$route.meta.roles[0];

      console.log(rolePath[routeRole])

      return rolePath[routeRole];
    },

    /**現在のロール取得 */
    currentRole(){
      //アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;

      // 優先度に応じてロールチェック
      if (roles.includes(ROLE.ADMIN)) return roles.includes(ROLE.ADMIN);
      if (roles.includes(ROLE.STAFF)) return !roles.includes(ROLE.STAFF);
      return false;
    },

    /** サイト管理者か？ */
    isAdmin() {
      //アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;

      // 優先度に応じてロールチェック
      return roles.includes(ROLE.ADMIN);
    },

  },

  methods: {
    //#region event
    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `/admin/Facility/create`,
      });
    },

    /**エンターキー押下 */
    onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        // case "areaId":
        //   if (!this.query.areaId) return;
        //   break;
        case "tagName":
          if (!this.query.tagName) return;
          break;
        case "zipcode":
          if (!this.query.zipcode) return;
          break;
        case "tel":
          if (!this.query.tel) return;
          break;
        case "facilityName":
          if (!this.query.facilityName) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },

    /** 検索ボタン押下 */
    onSearchClicked(value) {
      // 宿検索API URL
      let urlRequest;

      switch (value) {
        // case "areaId":
        //   urlRequest = this.makeUrlRequest(value, this.query.areaId);
        //   break;
        case "tagName":
          urlRequest = this.makeUrlRequest(value, this.query.tagName);
          break;
        case "zipcode":
          urlRequest = this.makeUrlRequest(value, this.query.zipcode);
          break;
        case "tel":
          urlRequest = this.makeUrlRequest(value, this.query.tel);
          break;
        case "facilityName":
          urlRequest = this.makeUrlRequest(value, this.query.facilityName);
          break;
        case "branchId":
          urlRequest = this.makeUrlRequest(value, this.query.selectedBranch);
          break;
        case "areaIds":
          urlRequest = this.makeUrlRequest(value, this.query.selectedAreaInfo.areaId);
          break;
      }

      if (!urlRequest) {
        // "検索条件を入力してください"
        alert(this.$i18n.t("facilitySearchPage.noQueryAlert"));
        return;
      }

      this.reqGetFacilities(urlRequest);
    },

    /**宿管理ボタン押下 */
    onManagementClicked(uniqueId){
      this.facilityEditPage(uniqueId);
    },

    /**ユーザー関連付けボタン押下 */
    onRelationClicked(uniqueId){
      this.facilityRelationUserSearchPage(uniqueId);
    },

    /**エリア一覧 都道府県表示押下 */
    onDisplayCityClicked(ken) {
      ken.display = !ken.display;
    },
    //#endregion event


    //#region method
    hotelStatusLabel(status) {
      const label = this.HOTEL_STATUS_LABEL
        .filter(a => a.value === status)
        .map(a => a.label);
      return label.length ? label[0] : 'Unknown';
    },

    /**
     * リクエストURL作成
     * @param key 検索クエリキー
     * @param value 検索クエリ値
     */
    makeUrlRequest(key, value) {
      const isKeyAndValue = !key || !value;
      return isKeyAndValue ? "" : `/rest/hotel/?${key}=${value}`;
      // return isKeyAndValue ? "" : `http://localhost:8080/rest/hotel/?${key}=${value}`;
    },

    /**テーブルボディを作成 */
    createSearchFacilityTableBodies(facilities){
      // 検索内容リストリセット
      this.facilities = [];

      facilities.forEach(facility => {
        // 検索した各宿をオブジェクトとして作成
        const facilityObj = {}
        const nullLabel = `${i18n.t("facilitySearchPage.facilitiesList.tableBody.notSet")}`

        facilityObj.uniqueId = facility.uniqueId;
        facilityObj.name = facility.name ? facility.name : nullLabel;
        facilityObj.branch = facility.branch ? facility.branch.name : nullLabel;
        facilityObj.zipcode = facility.zipcode ? facility.zipcode : nullLabel;
        facilityObj.address1 = facility.address1 ? facility.address1 : nullLabel;
        facilityObj.address2 = facility.address2 ? facility.address2 : nullLabel;
        facilityObj.address = `${facility.address1}${facility.address2}`;
        facilityObj.telephone = facility.telephone ? facility.telephone : nullLabel;
        facilityObj.status = facility.status ? facility.status : nullLabel;
        facilityObj.statusLabel = facility.status ? this.hotelStatusLabel(facility.status) : nullLabel;
        facilityObj.email = facility.email ? facility.email : nullLabel;

        this.facilities.push(facilityObj);
      });
    },

    /** 宿一覧の宿管理画面遷移 */
    facilityEditPage(uniqueId) {
      this.$router.push({
        path: "/operator/dashboard"
      });
      this.$store.commit('setUid',uniqueId);
      console.log(this.$store.state.uniqueId)
    },

    /** ユーザー関連付け画面遷移 */
    facilityRelationUserSearchPage(uniqueId) {
      console.log(uniqueId);
      
      this.$store.commit('setUid',uniqueId);
 
      this.$router.push({
        path: `/${this.pathRole}/Facility/UserRelation`
      });
    },

    /** 選択中の検索クエリか？ */
    isSelectedQuery(query) {
      return this.selectedQuery === query;
    },

    goPreview(facility) {
      const published = (facility.status & this.HOTEL_STATUS.FLAG_CONTENT_VISIBLE) !== 0;

      this.debugLog("facility");
      this.debugLog(facility);
      this.debugLog("published");
      this.debugLog(published);

      const location = published
        ? {
          name: "FacilityDetail", params: { uniqueId: facility.uniqueId },
        }
        : {
          name: "FacilityDetail", params: { uniqueId: facility.uniqueId },
          query: { preview: `${facility.telephone}${facility.zipcode}` },
        };

      let routeData = this.$router.resolve(location);

      window.open(routeData.href, "_blank");
    },

    //#endregion method

    //#region request

    /** 宿の登録件数取得 */
    reqGetFacilityTotalNumber() {
      
      const callback = (a) => {
        this.facilityCount = a.total;
        console.log(this.facilityCount);
      };

      this.reqGetHotelAll(callback);
    },

    /** 宿一覧取得リクエスト */
    reqGetFacilities(urlRequest) {
      this.facilities = [];
      this.facilityNotFound = false;

      const callback = (a) => {
        //レスポンスを格納
        this.createSearchFacilityTableBodies(a);
        // this.collapse = true;
        this.facilities.length === 0
          ? ((this.collapse = false), (this.facilityNotFound = true))
          : ((this.collapse = true), (this.facilityNotFound = false));

        this.areaListBox = false;
        this.reLoad++;
      };

      const errorCallback = (err) => {
        console.log(err);
        this.facilityNotFound = true;
      };

      this.reqGetSearchHotel(urlRequest,callback,errorCallback);
    },


    /**支店情報取得 */
    reqGetAllBranch() {
      this.facilities = [];
      this.facilityNotFound = false;

      const requestType = "s";

      const callback = (a) => {
        const branchList = a.list;

         branchList.forEach(branch => {
          const branchObj = {};

          branchObj.value = branch.id;
          branchObj.label = branch.name;
          this.branchOffice.push(branchObj);

         });

         //先頭に空の要素追加
         this.branchOffice.unshift({value : "",label : `${i18n.t("common.selectGuid")}`})
         
         console.log(this.branchOffice);
      };

      this.reqGetBranchAllByType(requestType,callback);
    },

    /**エリア情報取得 */
    reqGetAllAreaList() {
      this.facilities = [];
      this.facilityNotFound = false;

      const callback = (a) => {
        const areList = a.areas;
          this.fullAreaList = areList.map((a) => {
            a.display = false;
            if (a.childs) {
              a.childs.forEach((b) => {
                b.display = false;
                return b;
              });
            }
            return a;
          });
          console.log(this.fullAreaList)
          this.spinnerSide = false;
      };

      this.reqGetAreaList(callback);
    },

    //#endregion request

  },
  beforeMount() {
    //宿の登録件数取得
    this.reqGetFacilityTotalNumber();
    this.reqGetAllBranch();
    this.reqGetAllAreaList();
  },
};
</script>

<style lang="scss" scoped>

  .editor-title {
    font-size: 1.1em;
    border-left: 2px solid #194583;
    padding: 0 0 0 5px;
    margin-bottom: 10px;
  }

  .area-list-box {
      position: absolute;
      border: 1px solid #cccccc;
      min-width: 400px;
      background: #ffffff;
      z-index: 2;
    }
    .core-filter-section {
      position: relative;

      .select-date {
        position: relative;
        .custom-datepicker {
          position: absolute;
          z-index: 2;
          left: 0px;
          top: 50px;
        }
      }
    }
.prefecture-list {
  max-height: 58vh;
  overflow-y: auto;
  label,
  .core-area,
  .bi {
    cursor: pointer;
    margin: 0 !important;
  }
  li {
    clear: both;

    &.area-list {
      margin-bottom: 2px;
    }
    ul {
      margin-left: 20px;
    }
    .city-list {
      li {
        border-bottom: 1px solid #cccccc;
        display: block;
        margin-bottom: 2px;
        &:last-child {
          border: none;
        }
        label {
          margin: 0 !important;
          padding: 0 !important;
        }
        .city-name {
          display: flex;
          .city-check {
            margin: 7px 5px 0 0;
          }
        }
      }
    }
    .ken-list {
      .ken-title {
        border-bottom: 1px solid #cccccc;
        display: block;
        // border-radius: 4px;
        padding: 0;
        margin-top: 2px;
        label {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
}
.core-area {
  border-bottom: 1px solid #cccccc;
  display: block;
  // border-radius: 4px;
  padding: 0;
  .ic {
    margin-top: 5px;
  }
}

</style>